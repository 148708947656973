import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { getChatbot } from '../../api/service';
import { useTranslation } from 'react-i18next';

const RestartButton = (props) => {
    const { t } = useTranslation();

    const chat_bot_id = window.location.href.includes('chatbot_id=')
        ? window.location.href.split('chatbot_id=')[1].split('&')[0]
        : null;

    const [color, setColor] = useState('#1972F5');

    const fetchChatbotData = async (chatBotId) => {
        try {
            let response;
            if(localStorage.getItem('chatbots') && JSON.parse(localStorage.getItem('chatbots'))) {
                response = {data: JSON.parse(localStorage.getItem('chatbots'))[chatBotId]};
            } else {
                response  = await getChatbot(chatBotId);
            }
            const chatbotData = response.data;

            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };

    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=') 
            ? window.location.href.split('chatbot_id=')[1].split('&')[0] 
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    const handleRestart = () => {
        const chatbotsData = JSON.parse(localStorage.getItem('chatbots') || '{}');
        delete chatbotsData[chat_bot_id]['chat_id'];
        delete chatbotsData[chat_bot_id]['chat_messages'];

        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
        window.location.reload();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',  // Centrer le bouton horizontalement
                padding: '20px',           // Ajouter un peu d'espacement autour
            }}
        >
            <Button
                onClick={handleRestart}
                variant="contained"         // Utilisation du style 'contained' pour un bouton plein
                size="medium"               // Taille moyenne du bouton
                sx={{
                    background: color ,
                    padding: '8px 16px',    // Espacement interne simple
                    borderRadius: '8px',    // Coins légèrement arrondis
                    textTransform: 'none',  // Ne pas mettre le texte en majuscules
                }}
            >
                {t('restartChat')} {/* Utilisation de la fonction t pour traduire le texte */}
            </Button>
        </Box>
    );
};

export default RestartButton;
