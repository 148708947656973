import React, { useEffect, useState } from 'react';
import './MyCustomUserChatMessage.css'
import { getChatbot } from '../api/service';



const MyCustomUserChatMessage = (props) => {
    const [time, setTime] = useState(new Date());
    const [read, setRead] = useState(new Date());

    const [color, setColor] = useState('#1972F5');

    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            let response;
            if(localStorage.getItem('chatbots') && JSON.parse(localStorage.getItem('chatbots'))) {
                response = {data: JSON.parse(localStorage.getItem('chatbots'))[chatBotId]};
            } else {
                response  = await getChatbot(chatBotId);
            }
            const chatbotData = response.data;

            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
                document.querySelector('.react-chatbot-kit-chat-btn-send').style.opacity = 0.7
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };



    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    useEffect(() => {
        setTime(new Date());
        setRead(new Date());
    }, []);

    return (
        <div className={'message'} style={{ position: 'relative'}}>
            <div style={{background: `${color}`, color: 'white'}} className={'message-con'}>{props.message}</div>
            <span
                style={{
                    color: color,
                    position: 'absolute',
                    right: '1px',
                    fontSize: '13px'
                }}
            >
               {time && time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
        </div>

    )
}

export default MyCustomUserChatMessage
