import React, { useState, useEffect } from 'react';
import { getChatbot, getEntreprise } from "../api/service";
import './myHeader.css';

const MyHeader = () => {
    const [openNav, setOpenNav] = useState(false);
    const [color, setColor] = useState('#1972F5');
    const [name, setName] = useState('MCA');
    const [logo, setLogo] = useState(null);

    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            let response;
            if(localStorage.getItem('chatbots') && JSON.parse(localStorage.getItem('chatbots'))) {
                response = {data: JSON.parse(localStorage.getItem('chatbots'))[chatBotId]};
            } else {
                response  = await getChatbot(chatBotId);
            }
            const chatbotData = response.data;
            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
                setLogo(chatbotData.modules.chatbot.logo);
                if (chatbotData.entreprise_id || chatbotData.entreprise.id) {
                    await fetchEntrepriseData((chatbotData.entreprise_id || chatbotData.entreprise.id), chatBotId);
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };

    // Fonction pour récupérer les données de l'entreprise
    const fetchEntrepriseData = async (entrepriseId, chatBotId) => {
        try {
            let response ;
            if(localStorage.getItem('chatbots') && JSON.parse(localStorage.getItem('chatbots'))) {
                response = {data: JSON.parse(localStorage.getItem('chatbots'))[chatBotId].entreprise};
            } else {
                response  = await getEntreprise(entrepriseId);
            }
            const entrepriseData = response.data;
            if (entrepriseData) {
                setName(entrepriseData.nom);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données de l\'entreprise :', error);
        }
    };

    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    const resetChat = () => {
        const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
        const chatbotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;
        if (chatbotId) {
            delete chatbotsData[chatbotId]['chat_id']
            delete chatbotsData[chatbotId]['chat_messages']
            // Sauvegarde dans localStorage
            localStorage.setItem('chatbots', JSON.stringify(chatbotsData));

            window.location.reload()
        }
    };

    return (
        <div style={{ backgroundColor: color, position: 'relative' }}>
            <div style={{
                backgroundColor: color,
                padding: "10px 25px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}>
                <div className="logo-container">
                    {logo ? (
                        <img src={logo} alt="Logo" className="logo-image"/>
                    ) : (
                        <img src="/assets/chat.svg" alt="Default Logo" className="logo-image"/>
                    )}
                    <div className="status-indicator"></div>
                </div>
                <div style={{
                    color: '#ffffff',
                    textAlign: "center",
                    padding: '0 10px',
                    fontSize: '16px',
                    fontWeight: 'bold'
                }}>
                    {name}
                </div>
                <span className={'restart'} onClick={() => resetChat()}>↻</span>
            </div>
            <div className={'navBar'} style={{display: openNav ? 'flex' : 'none'}}>
                {/* Contenu de la barre de navigation */}
            </div>
        </div>
    );
};

export default MyHeader;
