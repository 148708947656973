import React from 'react';
import { Button, Box } from '@mui/material';
import { createClientMessage } from 'react-chatbot-kit';
import { useTranslation } from 'react-i18next';

const Choices = (props) => {
    const { setState, payload, actionProvider } = props;
    const { content, color } = payload;
    const { t } = useTranslation();

    const createChatUserMessage = (msg) => {
        const message = createClientMessage(msg);
        setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message]
        }));
        actionProvider.next(null, msg);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                width: '100%'
            }}
        >
            {
                content.map((item, index) => (
                    <Button
                        key={index}
                        variant="contained"
                        size="medium"
                        onClick={() => createChatUserMessage(t('helpFor') + item.name)} // Utiliser la traduction pour l'élément
                        sx={{
                            color: color, // Couleur du texte du bouton
                            backgroundColor: '#ffffff', // Couleur de fond du bouton
                            border: `1px solid ${color}`, // Correctement formaté avec les backticks
                            marginTop: '10px',
                            padding: '5px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            height: '75px',
                            width: '45%', // Remplit la largeur disponible
                            maxWidth: '200px', // Optionnel : limite maximale
                            fontSize: '12px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: color, // Couleur de fond au survol
                                color: '#ffffff', // Optionnel : Couleur du texte au survol
                            },
                        }}
                    >
                        {t(item.name)} {/* Utiliser la fonction t pour traduire le nom */}
                    </Button>
                ))
            }
        </Box>
    );
};

export default Choices;
